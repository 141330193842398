.c-button {
  width: auto;
  height: 30px;
  background-color: #5E6469;
  color: white;
  border: none;
  border-radius: 3px;
  display: inline-block;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear;
  cursor: pointer;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}


.c-button-wrapper {
  width: auto;
  margin-right: 0.2em;
  margin-left: 0.2em;
}

.c-button-wrapper-disabled {
  width: auto;
  margin-right: 0.2em;
  margin-left: 0.2em;
  pointer-events:none;
}

.c-button-wrapper-disabled .c-icon-button {
  background-color: #9EA1A4;
}

/* typical arctic admin button */
.c-active-admin-button {
  background-color: #5E6469;
  color: #fff;
  border: none;
  border-radius: 3px;
  display: inline-block;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear;
  cursor: pointer;
  padding: 5px 8px;
}

.c-active-admin-button a {
  color: #fff;
}