footer.frontend {

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 140px;
  }

  @media (min-height: 600px) {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 140px;
  }

  left: 0;
  width: 100%;
}

.frontend-horizontal-line-thick {
  border: 2px solid #707070;
  width: 100%;
  overflow-x: visible;
  opacity: 1;
}

.frontend-horizontal-line-thin {
  border: 1px solid #707070;
  width: 100%;
  overflow-x: visible;
  opacity: 1;
}

.frontend-login-btn {
  border: 1px solid #707070;
  border-radius: 15px;
  color: #008244
}
