.c-auto-update-packages-table {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 40px 1fr;
  grid-gap: 4px;
  grid-auto-rows: minmax(30px, auto);
}

/* .c-auto-update-packages-table-header {
  grid-column: 1 / 3;
  grid-row: 1;
} */

.c-header-cell {
  font-weight: bold;
}

.c-auto-update-banner {
  color: white;
  padding: 1em;
  margin-bottom: 1em;
}

.c-auto-update-banner.installing {
  background-color: red;
}

.c-auto-update-banner.succeeded {
  background-color: green;
}

.c-auto-update-banner.failed {
  background-color: red;
}