.c-button-group-vertical {
  display: flex;
  flex-direction: row;
}

.c-button-group-horizontal {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3em;
}
