.c-table-action-button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;

}