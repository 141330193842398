#sidebarMenu {
  position: fixed;
  right: 0;
  top: 150px;
  width: 250px;
  transform: translateX(500px);
  transition: transform 250ms ease-in-out;
  padding: 0.5em;
  margin-top: 37px;
}


#openSidebarMenu:checked ~ #sidebarMenu {
  transform: translateX(0);
}

#openSidebarMenu {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}

.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 110px; 
  right: 0px;
  height: 32px;
  width: 48px;
}
